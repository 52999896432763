import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = (props: { size: number }) =>
  makeStyles(
    {
      circularProgress: {
        position: 'absolute',
      },
      circularProgress100: {
        color: '#3e4cc830',
      },
      circularProgressContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '2px',
        pointerEvents: 'auto',
        position: 'relative',
      },
    },
    { index: 1 },
  )(props);
