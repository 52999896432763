import { ExtendedSources } from '@outmind/types';
import React from 'react';

import cloudIcon from '../../../assets/icons/cloud.png';
import informationIcon from '../../../assets/icons/information.png';
import lockIcon from '../../../assets/icons/lock.png';
import { useGetAuthUrl, useLinkHandler, useRuntimeEnv, useTranslations } from '../../../hooks';
import {
  CloseIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Link,
  Typography,
} from '../../../material';
import { AuthButton } from './AuthButton';
import { useStyles } from './styles';

/**
 * Renders a dialog that shows Reminders of privacy when adding a new source in Outmind
 * Also a button that redirect to be authenticated in the source
 * It depends on the source
 */
export const AddSourceConfirmationDialog: React.FC<AddSourceConfirmationDialogProps> = ({
  onClose,
  oneClickSource,
  open,
  source,
}) => {
  const { t } = useTranslations();
  const classes = useStyles();

  const { data: authUrl } = useGetAuthUrl(oneClickSource ?? source);
  const { inElectron } = useRuntimeEnv();
  const onClick = useLinkHandler(authUrl, { targetBlank: inElectron });

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={onClose}
      open={open}
    >
      <DialogContent>
        <IconButton
          aria-label="Close"
          className={classes.closeDialogButton}
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText
          className={classes.container}
          component="div"
          id="alert-dialog-description"
        >
          <div className={classes.helperTextContainer}>
            <div className={classes.helperIcon}>
              <img alt="Cloud icon" src={cloudIcon} />
            </div>
            <div>
              <Typography className={classes.helperText} variant="body1">
                {t('privacy_helper_text')}
              </Typography>
            </div>
          </div>

          <div className={classes.helperTextContainer}>
            <div className={classes.helperIcon}>
              <img alt="Cloud icon" src={lockIcon} />
            </div>
            <div>
              <Typography className={classes.helperText} variant="body1">
                {t('privacy_scope_helper_text')}
              </Typography>
            </div>
          </div>

          <div className={classes.helperTextContainer}>
            <div className={classes.helperIcon}>
              <img alt="Cloud icon" src={informationIcon} />
            </div>
            <div>
              <Typography className={classes.helperText} variant="body1">
                {`${t('see_our')} `}
                <Link
                  className={classes.faqLink}
                  color="inherit"
                  href="http://help.outmind.ai"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  FAQ
                </Link>
                {` ${t('for_more_information')}.`}
              </Typography>
            </div>
          </div>
        </DialogContentText>
        <div className={classes.reconnectButtonContainer}>
          <AuthButton onClick={onClick} source={source} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface AddSourceConfirmationDialogProps {
  onClose: () => void;
  oneClickSource?: 'o365' | 'gsuite';
  open: boolean;
  source: ExtendedSources;
}
